<template>
<div class="home">
    <div class="home-top">
        <SearchTop />
        <Swiper />
    </div>
    <div class="class-nav">
        <router-link to="/article/0">
            <span class="specil-tag">热</span>
            <div class="logo-wrap">
                <img src="../assets/examPolicy.png" alt="">
            </div>
            <div class="class-navtext">
                报考政策
            </div>
        </router-link>
        <router-link to="/article/1">
            <div class="logo-wrap">
                <img src="../assets/admiPolicy.png" alt="">
            </div>
            <div class="class-navtext">
                录取政策
            </div>
        </router-link>
        <router-link to="/article/2">
            <span class="specil-tag">必看</span>
            <div class="logo-wrap">
                <img src="../assets/addiPolicy.png" alt="">
            </div>
            <div class="class-navtext">
                加分政策
            </div>
        </router-link>
        <router-link to="/article/3">
            <div class="logo-wrap">
                <img src="../assets/specStudents.png" alt="">
            </div>
            <div class="class-navtext">
                特长生
            </div>
        </router-link>
        <router-link to="/article/4">
            <span class="specil-tag">新</span>
            <div class="logo-wrap">
                <img src="../assets/FAQ.png" alt="">
            </div>
            <div class="class-navtext">
                常见问题
            </div>
        </router-link>
    </div>

    <NoticeLine :news="newsList" />

    <div class="class-stu">
        <img class="stu-banner" src="../assets/banner.jpg" alt="banner">
        <div class="stu-nav">
            <div class="stu-nav-blk">
                <router-link to="/article/5" class="nav-btn">
                    <div>
                        <p class="nav-btn-text">学校辖区</p>
                        <p class="check-btn">点击查看<span class="arrow-icon"></span></p>
                    </div>
                    <img class="stu-icon" src="../assets/schoolIcon.png" alt="schoolIcon">
                </router-link>
            </div>
            <div class="stu-nav-blk">
                <router-link to="/article/6" class="nav-btn">
                    <div>
                        <p class="nav-btn-text">招生政策</p>
                        <p class="check-btn">点击查看<span class="arrow-icon"></span></p>
                    </div>
                    <img class="stu-icon" src="../assets/studentIcon.png" alt="schoolIcon">
                </router-link>
            </div>
            <div>

            </div>
        </div>
    </div>

    <div class="tabs-name">
        <a href="javascript:void(0)" class="tab-item" :class="{'on':tabNameIndex==1}" @click="setTabNameIndex(1)">头条通知</a>
        <a href="javascript:void(0)" class="tab-item" :class="{'on':tabNameIndex==2}" @click="setTabNameIndex(2)">往期精彩</a>
        <a href="javascript:void(0)" class="tab-item" :class="{'on':tabNameIndex==3}" @click="setTabNameIndex(3)">精品推荐</a>
        <a href="javascript:void(0)" class="tab-item" :class="{'on':tabNameIndex==4}" @click="setTabNameIndex(4)">名师指导</a>
        <a href="javascript:void(0)" class="tab-item" :class="{'on':tabNameIndex==5}" @click="setTabNameIndex(5)">名校推荐</a>

    </div>
    <div class="tabs-content">
        <ListItem v-for="newItem in newsList.slice(0,3)" :key="newItem.News_ID" :ids="newItem.News_ID" :title="newItem.News_Title" :reads="newItem.News_Count" :imgsrc="newItem.News_SmallImage" />
        <router-link to="/NewsList/1" class="check-more">查看更多</router-link>
    </div>

    <div class="class-stu">
        <img class="stu-banner" src="../assets/banner1.png" alt="banner">
    </div>

    <div class="class-tit">
        <p>适合你的老师</p>
        <router-link to="" class="check-more">查看更多</router-link>
    </div>
    <div class="teach-container">
        <div class="teach-show">
            <div>
                <div class="teach-img-wrp">
                    <img src="../assets/expTeach0.png">
                </div>
                <p class="teach-img-tit">一、名师课堂来袭 伴你轻松决战升学期</p>
            </div>
            <div>
                <div class="teach-img-wrp">
                    <img src="../assets/expTeach1.png">
                </div>
                <p class="teach-img-tit">二、名师课堂来袭 伴你轻松决战升学期</p>
            </div>
            <div>
                <div class="teach-img-wrp">
                    <img src="../assets/expTeach0.png">
                </div>
                <p class="teach-img-tit">三、名师课堂来袭 伴你轻松决战升学期</p>
            </div>
            <div>
                <div class="teach-img-wrp">
                    <img src="../assets/expTeach1.png">
                </div>
                <p class="teach-img-tit">四、名师课堂来袭 伴你轻松决战升学期</p>
            </div>
            <div>
                <div class="teach-img-wrp">
                    <img src="../assets/expTeach0.png">
                </div>
                <p class="teach-img-tit">五、名师课堂来袭 伴你轻松决战升学期</p>
            </div>
        </div>
    </div>

    <div class="class-stu">
        <img class="stu-banner" src="../assets/banner2.png" alt="banner">
    </div>
    <div class="class-tit">
        <p>顶尖名校任你挑选</p>
        <router-link to="" class="check-more">查看更多</router-link>
    </div>

    <ImgSwitch />

    <a href="https://www.visasiv.cn/ali/alipay_online.html?courierid=20908" class="ad-tui">
        <img src="../assets/rq.jpg" alt="燃气">
    </a>


    <!-- <div class="class-tit">
        <p>往期精彩</p>
        <router-link to="/NewsList" class="check-more">查看更多</router-link>
    </div>
    <div style="background:#fff">
        <ListItem v-for="newItem in newsList.slice(3,newsList.length-1)" :key="newItem.News_ID" :ids="newItem.News_ID" :title="newItem.News_Title" :reads="newItem.News_Count" :imgsrc="newItem.News_SmallImage" />
    </div> -->

    <NavBottom :checkNum="1" />
</div>
</template>

<script>
export default {
    name: "Home",
    data() {
        return {
            tabNameIndex: 1,
            pageNewsIndex: 1,
            newsList: [],
        };
    },
    mounted() {
        localStorage.setItem("gUrl",this.$route.fullPath||"/")
        console.log("fullPath",this.$route.fullPath)
        this.getLogin()
        this.getNewsList()
    },
    methods: {
        setTabNameIndex(num) {
            this.tabNameIndex = num;
        },
        getNewsList() {
            this.wpost({
                url: '/Ajax/Member/getnewslist',
                data: {
                    pagesize: 20,
                    pageindex: this.pageNewsIndex,
                    sort: 1
                },
                succ: (res) => {
                    console.log("newsList:", res)
                    this.newsList = res.list
                }
            })
        },
        getLogin() {
            console.log(document.cookie)
            let cookieStr = document.cookie
            console.log("indexOf:", cookieStr.indexOf('Member_ID'))
            if (cookieStr.indexOf('Member_ID') == -1) {
                location.href = this.domin + "/gologin.aspx?RedirectUrl=www.visasiv.cn/testlife";
                return false
            }

            let cookieStrArr = cookieStr.split(";")
            let cookieObj = null
            for (let i = 0; i < cookieStrArr.length; i++) {
                let temp = cookieStrArr[i].split("=")
                let keyName = temp[0].trim()
                if (keyName == 'Member_ID') {
                    cookieObj = {
                        [keyName]: temp[1]
                    }
                }

            }
            console.log(cookieObj)
            localStorage.setItem('memberid', cookieObj.Member_ID)

        },
    }
};
</script>

<style scoped>
.ad-tui{
    display: block;
    margin: 12px;
    text-align: center;
}
.ad-tui img{
    width:100%
}
.home-top {
    background-color: #f5f5f5;
    background-image: url("../assets/top_bg.jpg");
    background-repeat: no-repeat;
    background-size: 100% auto;
    background-position: center top;
    padding: 5px;
}
.tabs-content{
    background:#fff;
}
.tabs-content .check-more{
    font-size: 12px;
    padding: 6px 0;
    color: #999

}
.tabs-content .check-more::after,.tabs-content .check-more::before{
    display: none;
}
/* 五个导航样式 */
.class-nav {
    display: flex;
    justify-content: space-around;
    background-color: #fff;
    border-radius: 6px;
    padding: 10px 0;
    margin: 0 5px;
}

.class-nav a {
    position: relative;
    text-decoration: none;
}

.logo-wrap {
    height: 40px;
    width: 40px;
    margin: 3px auto;
    text-align: center;
    border-radius: 50%;
    background-image: linear-gradient(to right, #606cfc, #4692f1);
    display: flex;
    justify-content: center;
    align-items: center;
}

.logo-wrap img {
    height: 60%;
    vertical-align: middle;
}

.class-navtext {
    font-size: 13px;
    font-weight: bold;
    color: #333;
}

/* 两个导航样式 */

.class-stu {
    margin: 10px 5px;
    border-radius: 8px;
    background-color: #fff;
    font-size: 0;
}

.stu-banner {
    width: 100%;
    border-radius: 8px;
    overflow: hidden;
}

.stu-nav {
    display: flex;
}

.stu-nav-blk {
    width: 50%;
}

.stu-nav-blk a {
    position: relative;
    padding: 10px 0;
    display: flex;
    color: #333;
    text-decoration: none;
    align-items: center;
    justify-content: space-around;
}

.stu-nav-blk:first-child a::after {
    content: "";
    position: absolute;
    top: 12px;
    bottom: 12px;
    width: 0;
    right: 0;
    border-right: 1px solid #666;
}

.stu-nav-blk p {
    padding: 0;
    margin: 0;
    text-align: left;
}

.nav-btn-text {
    font-size: 13px;
    font-weight: bold;
}

.stu-nav .stu-icon {
    height: 46px;
}

.stu-nav .check-btn {
    font-size: 12px;
    color: #666;
}

.arrow-icon {
    top: 1px;
    position: relative;
    display: inline-block;
    height: 12px;
    width: 12px;
    line-height: 12px;
    font-size: 10px;
    text-align: center;
    color: #fff;
    background: #666;
    border-radius: 50%;
    margin-left: 3px;
}

.arrow-icon::before {
    content: "";
    height: 4px;
    width: 4px;
    position: absolute;
    top: 3px;
    left: 2.5px;
    border: 1px solid transparent;
    border-right-color: #fff;
    border-bottom-color: #fff;
    transform: rotateZ(-45deg);
}

.tabs-name {
    text-align: center;
    display: flex;
    padding: 5px 0;
}

.tab-item {
    width: 20%;
    font-size: 13px;
    color: #333;
    font-weight: bold;
    position: relative;
    text-decoration: none;
}

.tab-item::after {
    transition: width 300ms ease, border-color 400ms ease;
    transform-origin: center center;
    content: "";
    position: absolute;
    width: 20%;
    left: 50%;
    transform: translateX(-50%);
    height: 0;
    bottom: -3px;
    border-bottom: 2px solid transparent;
}

.tab-item.on::after {
    width: 55px;
    border-bottom-color: #fa583f;
}

.class-tit {
    display: flex;
    justify-content: space-between;
    align-items: center;
    padding: 5px;
    margin: 6px 0
}

.class-tit p {
    font-size: 14px;
    font-weight: bold;
    color: #333;
}

.class-tit a {
    font-size: 11px;
    color: #999;
}

.teach-container {
    width: 100%;
    overflow: hidden;
    overflow-x: auto;
}

.teach-show {
    display: flex;
}

.teach-show::after {
    content: "";
    display: block;
    clear: both;
}

.teach-show>div {
    width: 36%;
    margin: 5px 1%;
}

.teach-img-wrp img {
    width: 100%;
    object-fit: contain;
}

.teach-img-tit {
    font-size: 12px;
    white-space: nowrap;
    overflow: hidden;
    text-overflow: ellipsis;
}
</style>
